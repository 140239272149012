@charset "UTF-8";
.collapsebar .asideBar {
  width: 46px;
}
.collapsebar .asideBar span.link {
  display: none;
}
.collapsebar .asideBar span.icon {
  width: 50px;
}
.collapsebar .content {
  width: calc(100% - 50px);
  float: left;
  margin-left: 50px;
}
.collapsebar .menuLogo img {
  opacity: 0;
}
.collapsebar .cl img {
  opacity: 1;
}
.collapsebar .container {
  max-width: 100%;
}

.cl img {
  opacity: 1;
  width: 150px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.asideBar {
  width: 190px;
  /* position: fixed; */
  top: 64px;
  left: 0;
  bottom: 0;
  /* height: 100vh; */
  height: 100%;
  overflow: auto;
  /* float: left; */
  padding-bottom: 40px;
  background: #f1efef;
}
.asideBar .menuLogo {
  width: 70%;
  margin-top: 0px;
  margin-left: 10%;
  float: left;
}
.asideBar .menuLogo img {
  width: 100%;
}
.asideBar .menuBar {
  float: left;
  width: 80%;
  margin-left: 10%;
}
.asideBar .menuBar > ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.asideBar .menuBar > ul > li {
  padding-top: 8px;
  padding-bottom: 8px;
}
.asideBar .menuBar > ul > li > a {
  /* font-weight: 500; */
  color: #8b8f96;
  font-size: 18px;
  text-decoration: none;
}
.asideBar .menuBar > ul > li > a:hover {
  text-decoration: none;
  background: #1f3c59;
  color: #fff;
}
.asideBar .menuBar > ul > li ul {
  list-style-type: none;
  display: none;
}
.asideBar .menuBar > ul > li ul li a {
  color: #8b8f96;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
}
.asideBar .menuBar > ul > li ul li a:hover {
  text-decoration: none;
}
.asideBar .menuBar > ul > li ul li a:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: relative;
  left: -5px;
}

.ftabs {
  text-align: center;
}

.form-tab {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.form-tab li {
  display: inline-block;
  position: relative;
  padding: 5px 10px;
  z-index: 2;
}
.form-tab li .tag {
  font-size: 30px;
  margin-bottom: 10px;
  color: #8b8f96;
}
.form-tab li .text {
  color: #8b8f96;
}
.form-tab li .ball {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ff6d41;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}
.form-tab li .ball i {
  vertical-align: middle;
  position: relative;
  bottom: -1px;
  font-size: 20px;
}
.form-tab li:before {
  content: "";
  position: absolute;
  width: 120px;
  background: #1f3c59;
  height: 2px;
  bottom: 59px;
  left: 61px;
  z-index: 1;
}
.form-tab li:last-child:before {
  content: "";
  display: none;
}
.form-tab li.active .ball {
  background: #1f3c59;
}
.form-tab li.active .text {
  color: #1f3c59;
  font-weight: bold;
}
.form-tab li.active .tag {
  color: #ff6d41;
}

.form-btn-red {
  background: #ff6d41;
}

.form-btn-blue {
  background: #1f3c59;
}

.form-btn {
  padding: 6px 52px;
  color: #fff;
  border: 0px;
  outline: 0px;
}

a.form-btn {
  padding: 7px 52px;
  color: #fff;
  border: 0px;
  outline: 0px;
}
a.form-btn:hover {
  text-decoration: none;
}

.btn-red {
  background: #ff6d41;
  color: #fff;
}

.btn-blue {
  background: #1f3c59;
  color: #fff;
}

.blue {
  color: #1f3c59;
}

body {
  font-family: "Lato", sans-serif;
}

.wrapper {
  /* width: 100%;
  float: left; */
  position: relative;
}

.content {
  width: calc(100% - 200px);
  /* float: left; */
  /* margin-left: 200px; */
  /* height: 100vh; */
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}

thead tr {
  border-bottom: 3px solid #ff6d41;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #8b8f96;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4b2aa4;
  border-radius: 10px;
}

/*# sourceMappingURL=style.css.map */

/*--- Custom Css 12 October 2020 ----*/

.form-tab li:before,
.form-tab li:after,
.form-tab li:last-child:before {
  content: "";
  position: absolute;
  width: 50%;
  background: #1f3c59;
  height: 2px;
  bottom: 59px;
  right: 50%;
  z-index: 1;
  margin-right: 18px;
  left: auto;
  display: block !important;
}
.form-tab li:after {
  left: 50%;
  margin-left: 18px;
  right: auto;
}

.form-tab li:last-child:after,
.form-tab li:first-child:before {
  display: none !important;
}

form .innr-select-box {
  float: none;
  display: inline-block;
  width: 100%;
}
form .innr-select-box span.radio {
  float: none;
  display: inline-block;
  position: relative;
}
form .innr-select-box span.radio + span.radio {
  margin-left: 15px;
}
form .innr-select-box span.radio input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
form .innr-select-box span.radio label {
  padding: 5px 30px;
  color: #fff;
  border: 0px;
  outline: 0px;
  background: #ccc;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 17px;
}
form .innr-select-box span.radio input:checked + label {
  background: #ff6d41;
}
form span.checkbox label {
  margin-right: 10px;
  float: left;
  line-height: 20px;
}
a.form-btn {
  text-align: center;
}
.form4 .form-group label {
  width: 100%;
  text-align: center;
}
.form4 .form-group label img {
  min-width: 150px;
}

.form-tab li .ball {
  background: #1f3c59;
}
.form-tab li.active .ball {
  background: #ff6d41;
}
.form-btn {
  padding: 5px 52px;
}

@media (max-width: 575px) {
  .form-tab li {
    width: 100%;
    padding-bottom: 60px;
  }
  .form-tab li:after {
    margin-left: 0;
    bottom: 0;
    width: 2px;
    height: 68px;
    margin-bottom: 0px;
  }
  .form-tab li:before,
  .form-tab li:last-child:before {
    display: none !important;
  }
  form .innr-select-box span.radio label {
    padding: 5px 20px;
  }

  .form-btn {
    padding: 5px 30px;
  }
  a.form-btn {
    padding: 8px 20px;
  }

  form .right-btn-box {
    text-align: center !important;
    padding-top: 20px;
  }
  form .right-btn-box input {
    float: none;
    display: inline-block;
  }
  form .right-btn-box .form-btn-blue {
    margin-top: -102px;
    position: relative;
    float: right;
    padding: 6px 20px;
  }

  form .right-btn-box-01 .form-btn-blue {
    margin-top: -116px;
  }
  form .mid-btn-box {
    padding-top: 20px;
    text-align: center;
  }
  form .mid-btn-box input {
    max-width: 100%;
    white-space: normal;
    word-break: break-all;
  }

  .btn-block {
    display: inline-block !important;
    width: auto !important;
  }

  form .right-btn-box-02 .form-btn-blue {
    margin-top: -113px;
    padding: 8px 20px;
  }
  form .right-btn-box-02 input {
    margin-top: -113px;
    padding: 8px 20px;
  }
}

mat-error {
  color: red;
}

.ck.ck-content {
  max-height: 300px;
  overflow-y: scroll;
}

/*--- Custom Css 12 October 2020 ----*/

.form-tab li:before,
.form-tab li:after,
.form-tab li:last-child:before {
  content: "";
  position: absolute;
  width: 50%;
  background: #1f3c59;
  height: 2px;
  bottom: 59px;
  right: 50%;
  z-index: 1;
  margin-right: 18px;
  left: auto;
  display: block !important;
}
.form-tab li:after {
  left: 50%;
  margin-left: 18px;
  right: auto;
}

.form-tab li:last-child:after,
.form-tab li:first-child:before {
  display: none !important;
}

form .innr-select-box {
  float: none;
  display: inline-block;
  width: 100%;
}
form .innr-select-box span.radio {
  float: none;
  display: inline-block;
  position: relative;
}
form .innr-select-box span.radio + span.radio {
  margin-left: 15px;
}
form .innr-select-box span.radio input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
form .innr-select-box span.radio label {
  padding: 5px 30px;
  color: #fff;
  border: 0px;
  outline: 0px;
  background: #A4A4A4;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 17px;
}
form .innr-select-box span.radio input:checked + label {
  background: linear-gradient(to bottom right, #783AD0, #D8327D);
}
form span.checkbox label {
  margin-right: 10px;
  float: left;
  line-height: 20px;
}
a.form-btn {
  text-align: center;
}
.form4 .form-group label {
  width: 100%;
  text-align: center;
}
.form4 .form-group label img {
  min-width: 150px;
}

.form-tab li .ball {
  background: #1f3c59;
}
.form-tab li.active .ball {
  background: #ff6d41;
}
.form-btn {
  padding: 5px 52px;
}

form label {
  font-weight: 600;
}
form label.full-width {
  width: 100%;
  color: black;
}
form label.full-width span.req {
  float: right;
  color: #ff0000;
  font-size: 14px;
}
form span.small-text {
  float: left;
  width: 100%;
  font-size: 13px;
}
form label.full-width span.small-text {
  font-weight: 500;
}
form span.keyword-search-box {
  position: relative;
  float: none;
  display: inline-block;
  width: 100%;
  /* max-width: 400px; */
}
form span.keyword-search-box input {
  width: 100%;
}
form span.keyword-search-box i {
  position: absolute;
  right: 8px;
  top: 10px;
  font-size: 17px;
}
form .outer-form-box {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #888;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 15px;
}
form .outer-form-box:focus {
  outline: none;
}
form .outer-form-box ul.fea-list {
  padding-left: 20px;
  margin-bottom: 0;
}
form .outer-form-box ul.fea-list li {
  text-indent: 12px;
}
form .outer-form-box ul.fea-list li + li {
  margin-top: 5px;
}
form .outer-form-box .innr-des {
  min-height: 70px;
}
form .outer-form-box h5 {
  font-size: 1rem;
}

.onoffswitch {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 35px;
  padding: 0;
  line-height: 35px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #ff6d41;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #eeeeee;
  color: #999999;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 35px;
  margin: 0;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  border: 2px solid #999999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.mon .onoffswitch-inner:before {
  content: "Mon";
}
.tue .onoffswitch-inner:before {
  content: "Tue";
}
.wed .onoffswitch-inner:before {
  content: "Wed";
}
.thurs .onoffswitch-inner:before {
  content: "Thu";
}
.fri .onoffswitch-inner:before {
  content: "Fri";
}
.sat .onoffswitch-inner:before {
  content: "Sat";
}
.sun .onoffswitch-inner:before {
  content: "Sun";
}

.hour-select-box {
  position: relative;
  padding-left: 85px;
  max-width: 300px;
}
.hour-select-box select {
  border: none !important;
  outline: none !important;
  width: 100%;
}
.hour-select-box .select2-container--default .select2-selection--single {
  border: none;
}
.hour-select-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 25px;
}
.hour-select-box label {
  position: absolute;
  left: 15px;
  top: 7px;
}
.hour-select-box .select2-container {
  width: 100% !important;
}

.time-list {
  list-style: none;
  padding: 0 0;
}
.time-list ul.sub-list {
  padding: 0 0;
  list-style: none;
}
.time-list > li {
  float: left;
  width: 100%;
}
.time-list ul.sub-list li {
  float: left;
  width: 120px;
}

.rule-box {
  float: none;
  display: inline-block;
  text-align: left;
}
.rule-box label {
  min-width: 172px;
}
.rule-box input[type="number"] {
  width: 100px;
  text-align: center;
}

.calendar-outer-box {
  float: none;
  display: inline-block;
  padding-top: 20px;
}
.calendar-box {
  float: right;
  min-width: 400px;
}
.calendar-outer-box .date-box {
  float: left;
  text-align: right;
}
.calendar-outer-box .date-box span {
  display: inline-block;
  float: none;
  width: auto;
  padding: 6px 18px;
  margin-right: 25px;
  color: #ff6d41;
  font-weight: bold;
  font-size: 20px;
}
.calendar-outer-box .date-box h5 {
  padding-right: 8px;
  margin-top: 100px;
}
.calendar-outer-box .date-box h5 span {
  padding: 0 0;
  margin: 0 0;
  text-decoration: underline;
}
h6 span {
  color: #ff6d41;
}
.pignose-calendar {
  width: 100% !important;
  max-width: 100% !important;
}

.form-tab li a {
  color: #fff;
}
.form-tab li .text a {
  color: #8b8f96;
}
.form-tab li.active .text a {
  color: #1f3c59;
}

@media (max-width: 1024px) {
  .form7 .form-btn,
  .form2 .form-btn,
  .form4 .form-btn,
  .form3 .form-btn,
  .form4 input[type="submit"],
  .form5 .form-btn,
  .form5 input[type="submit"],
  .form6 .form-btn,
  .form6 input[type="submit"],
  .form6 input[type="button"],
  .form8 .form-btn,
  .form8 input[type="submit"] {
    padding: 7px 15px !important;
    font-size: 14px;
  }
  .form7 .left-btn-box,
  .form2 .left-btn-box,
  .form3 .left-btn-box {
    padding-top: 0 !important;
  }
  .form7 .left-btn-box a,
  .form2 .left-btn-box a,
  .form3 .left-btn-box a {
    float: left;
    margin-top: 0 !important;
  }
  .form7 input[type="submit"].form-btn,
  .form2 input[type="submit"].form-btn,
  .form3 input[type="submit"].form-btn {
    position: absolute;
    right: 125px;
  }
  .form7 .right-btn-box a.form-btn,
  .form2 .right-btn-box a.form-btn,
  .form3 .right-btn-box a.form-btn {
    float: right;
  }
}

@media (max-width: 575px) {
  .form-tab li {
    width: 100%;
    padding-bottom: 60px;
  }
  .form-tab li:after {
    margin-left: 0;
    bottom: 0;
    width: 2px;
    height: 68px;
    margin-bottom: 0px;
  }
  .form-tab li:before,
  .form-tab li:last-child:before {
    display: none !important;
  }
  form .innr-select-box span.radio label {
    padding: 5px 20px;
  }

  .form-btn {
    padding: 5px 15px;
  }
  a.form-btn {
    padding: 8px 15px;
  }

  form .right-btn-box {
    text-align: center !important;
    padding-top: 20px;
  }
  form .right-btn-box input {
    float: none;
    display: inline-block;
  }
  form .right-btn-box .form-btn-blue {
    margin-top: -102px;
    position: relative;
    float: right;
    padding: 6px 20px;
  }

  form .right-btn-box-01 .form-btn-blue {
    margin-top: -116px;
  }
  form .mid-btn-box {
    padding-top: 20px;
    text-align: center;
  }
  form .mid-btn-box input {
    max-width: 100%;
    white-space: normal;
    word-break: break-all;
  }

  .btn-block {
    display: inline-block !important;
    width: auto !important;
  }

  form .right-btn-box-02 .form-btn-blue {
    margin-top: -113px;
    padding: 8px 15px;
  }
  form .right-btn-box-02 input {
    margin-top: -113px;
    padding: 8px 15px;
  }

  .time-list ul.sub-list {
    float: left;
    width: 100%;
    margin-bottom: 25px;
  }
  .time-list ul.sub-list li + li {
    width: 80px;
  }

  form .right-btn-box-03 .form-btn-blue {
    margin-top: -86px;
  }
  .rule-box {
    float: left;
    width: 100%;
    text-align: left;
  }
  .rule-box label {
    min-width: 100%;
  }

  form .mid-btn-box-02 input {
    width: 100% !important;
  }
  form .right-btn-box-04 .form-btn-blue {
    margin-top: -184px;
    padding: 8px 20px;
  }

  form .mid-btn-box-03 {
    padding-top: 0;
  }
  form .mid-btn-box-03 input {
    padding: 8px 15px;
  }

  form .right-btn-box-04 .form-btn-blue {
    margin-top: 0;
    width: 100% !important;
  }
  .form2 .right-btn-box .form-btn-blue {
    margin-top: -52px;
  }
  .form6 .right-btn-box-04 a.form-btn-blue {
    margin-top: -184px;
    width: auto !important;
  }
}

/** for deal review detail page for partner */
.review-detail-table textarea {
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  color: #212529;
  border-color: #dee2e6;
  margin: 15px 0 15px 0;
}
.review-detail-table textarea::placeholder {
  color: #8b8f96;
}
.review-detail-table .btn {
  background: #ff754c;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  border: 1px solid #ff754c;
}
.review-detail-table .btn:hover {
  color: #ff754c;
  background-color: transparent;
}
.review-detail-table .heading img {
  height: 33px;
  margin-right: 15px;
}
.review-detail-table .review-info {
  width: 50%;
  padding-right: 25px;
}
.review-detail-table .review-photos {
  width: 50%;
  padding-left: 25px;
}
.review-detail-table .review-photos ul {
  padding: 0;
  list-style: none;
  margin-left: -10px;
}
.review-detail-table .review-photos ul li {
  padding-left: 10px;
  width: 33%;
  height: 130px;
  padding-bottom: 10px;
  position: relative;
}
.review-detail-table .review-photos ul li img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.review-detail-table .published-by {
  margin-top: 30px;
}
.review-detail-table .published-by span:first-child {
  padding-right: 20px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.icofont-star.active {
  color: #ff7146;
}
