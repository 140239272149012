.chat-container {
    z-index: 100;
    position: absolute;
    top: 45px;
    right: 20px;
    width: 80%;    
}

@media (min-width:768px) and (max-width: 1023px){
	.chat-container { width: 50%; }
}
@media (min-width:1024px) and (max-width: 1399px){
	.chat-container { width: 40%; }
}
@media (min-width:1400px){
	.chat-container { width: 30%; }
}

.headicons a{
    /*color:#ff6d42;*/
    color:#ddd;
    font-size:30px;
    position: relative;
    text-decoration: none;
}
.headicons a:hover{
    /*color:#ff6d42;*/
    color:#ddd;
    font-size:30px;
    position: relative;
    text-decoration: none;
}
.headicons a.active{
    color:#ff6d42;
    
}
.headicons a small{
font-size: 16px;
position: absolute;
top: 36px;
left: -9px;
font-weight: 500
}
.chats{
width:100%;
float:left;
}
.chats ul{
 width: 100%;
 list-style: none;
 padding:0px;
 margin: 0px;
}
.chats ul li{
 width:calc(100% - 10px);
 padding-left: 10px;
 padding-top: 5px;
 padding-bottom: 5px;
  
}
.chats ul .heading{
 width: 100%;
 background: #ddd;
 font-weight: 600;
  
}
.chats ul .message {
 padding-top: 8px;
 padding-bottom: 8px;
 width:100%;
 float: left;
}
.chats ul li.message .image{
 width: 50px;
 height: 50px; 
 float: left;
 margin-top: 8px;
}
.chats ul li.message .star{
line-height: 50px;
color:#ff6d42;
}
.chats ul li.message .status{
 width:20px;
 height:50px;
 float:left;
}
.chats ul li.message .circle{
line-height: 50px;
color:green;
}
.chats ul li.message .text{
 width: calc(100% - 80px);
 margin-left: 10px;	  
 float: left;
}
.chats ul li.message .text p{
position: relative;
top: -4px;
font-size: 0.8rem;
}
.chats ul li.message .text h6{
    cursor: pointer;
    font-weight: 500;
    color: #ff6d42;
}
.chats ul li.message img{
 width: 100%;
 float:left; 
 border-radius: 50%;
 overflow: hidden;
}
.chats .img-circle {
    margin-top: 0px;
    margin-bottom: 0px;
}
.chat-body{
height: 450px;
overflow: auto; 
}
.chats ul li .mesageBox{
width:100%;
float:left;
}

.mainheading{
width: 100%;
}
.mainheading .icon{
width: 20px;
float:left;
}
.mainheading .input{
width: calc(100% - 60px);
float:left;
line-height: 30px;
}
.mainheading .input input{
  height: 30px;
  border-radius: 22px;
}
.mainheading .image{
width: 40px;
float:left; 
}
.mainheading .image img{
width: 35px;
height: 35px;
border-radius: 50%; 
margin-left: 5px;
}
.chat-foot img {
width: 60%;
margin-left: 20%;
}
.chat-foot  {
border:0px;
}

.chat-message-input-box {
    width: 77%;
    display: inline !important;
}

.chat-messages-scrollable {
    overflow-y: scroll; max-height: 335px;
}

.chat-message {
    width: 100%;
    min-height: 24px;
    font-size: 14px;
    display: inline-block;
}

.chat-message-text {
    padding: 5px 5px !important;
    border-radius: 5px;
    max-width: 70%;
}

.chat-message-text.from-me {
    float: right;
    background-color: #8080805e;
    color: black;
}

.chat-message-text.to-me {
    float: left;
    background-color: #ff6d416b;
    color: rgb(34, 18, 18);
}

.chat-message-sender {
    bottom: 10px;
    position: absolute;
}

.chat-message-time {
    float: right;
    font-size: 9px;
    display: flex;
    margin: 0px 0px;
    color: blue;
}
