/* You can add global styles to this file, and also import other style files */
$locoldeal-light-pink: #D8327D;
$locoldeal-dark-grey: #5C5C5C;
$locoldeal-light-grey-font: #777777;

* {
  font-family: 'Switzer', sans-serif;
}

.btn {
  &.custom-btn-orange {
    border-radius: 10px;
    font-weight: 700;
    font-size: 22px;
    padding: 8px 30px;
    color: #fff;
    border: 1px solid #e83600;
    white-space: nowrap;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    background: linear-gradient(180deg, #ff6d41 0%, #e83600 100%);

    &:hover {
      background: linear-gradient(180deg, #1f3c59 0%, #041d34 100%);
    }

    &.light {
      &:hover {
        background: #fff;
        color: #e83600;
      }
    }
  }
}

.container {
  @media (min-width: 1700px) {
    max-width: 1640px !important;
  }

  &-lg,
  &-md,
  &-sm,
  &-xl,
  &-xxl {
    @media (min-width: 1700px) {
      max-width: 1640px !important;
    }
  }
}

.toast-container {
  position: fixed !important;
  z-index: 999999 !important;
  margin: 5px;
}

.bg-gradient-light {
  background: linear-gradient(to top right, #d1f0d5, #d5d1f0, #f0d5d1);
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to bottom right, #d8327d, #783ad0);
  border-image-slice: 2;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.common-gradient-text {
  background: -webkit-linear-gradient(45deg, #4f2190 0%, #d41a6e 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.customer-popup {
  padding-bottom: 20px !important;

  li {
    float: left;
    width: 100%;

    label {
      float: left;
      width: 100%;
      font-size: 15px;
      letter-spacing: 0.5px;
      color: #EE410D;
    }

    span.text {
      float: left;
      width: 100%;

      input {
        float: left;
        width: 100%;
        border: none;
        outline: none;
        height: 45px;
        line-height: 45px;
        padding: 0 10px;
        font-size: 13px;
        letter-spacing: 0.5px;
        border-radius: 6px;
        border: 1px solid;
        border-image: linear-gradient(to top right, #783ad0, #d8327d) 1;
        outline: none;
        background-clip: padding-box;
        -webkit-appearance: none;
      }
    }

    span.text-area {
      textarea {
        border: 1px solid;
        border-image: linear-gradient(to top right, #783ad0, #d8327d) 1;
        outline: none;
        background-clip: padding-box;
        -webkit-appearance: none;
      }
    }

    .submit-btn input {
      float: left;
      width: 100%;
      text-align: center;
      background: linear-gradient(95.05deg, #FF7261 2.54%, #F6410A 99.45%);
      ;
      height: 40px;
      padding: 9px 120px;
      color: #fff;
      text-transform: uppercase;
      border: none;
      outline: none;
      letter-spacing: 1px;
      font-size: 15px;
      border-radius: 8px;

      &:hover {
        border: 1px solid #EE410D;
        background: none;
        color: black;
      }
    }
  }
}

.custom-btn-purple-gradient {
  min-width: 115px;
  height: 37px;
  margin: auto;
  font-size: 15px;
  padding: 8px 17px;
  border: 1px solid #783AD0;
  border-radius: 5px;
  white-space: nowrap;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  font-weight: 400;
  color: #fff;
  background: linear-gradient(to bottom right, #783AD0, #D8327D);

  &:hover {
    background: none;
    color: #000;
  }
}

.custom-btn-orange-gradient {
  color: white;
  width: 126px;
  height: 37px;
  font-size: 13px;
  padding: 8px 8px;
  color: #fff !important;
  border-radius: 10px;
  white-space: nowrap;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background: linear-gradient(to bottom right, #FF7261, #F6410A);
  border: 2px solid transparent;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: none;
    border: 2px solid #e83600;
    color: black !important;
  }
}

.w-fit-content {
  width: fit-content !important;
}


.form-control {
  color: $locoldeal-light-grey-font;
  font-size: 16px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;

  &:not(.is-invalid, .is-valid) {
    border-color: $locoldeal-light-grey-font;
  }

  &[type="file"] {
    border: none;
    width: auto;
    height: auto;
  }

  &:focus {

    // box-shadow: none;
    &:not(.is-invalid, .is-valid) {
      border-color: $locoldeal-light-pink;
    }
  }

  &::-webkit-input-placeholder {
    color: $locoldeal-light-grey-font;
  }

  &:-ms-input-placeholder {
    color: $locoldeal-light-grey-font;
  }

  &::placeholder {
    color: $locoldeal-light-grey-font;
  }
}

.w-60 {
  width: 60% !important;
}

.f-15 {
  font-size: 15px !important;
}


.card.is-loading {

  img,
  h2,
  h5,
  p,
  button {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .card-body-title {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    height: 25px;
    width: 100%;
  }

  .custom-btn-wrapper {
    button {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      height: 31px;
      cursor: default;
      width: 100%;
    }
  }

  .image {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h2 {
    height: 30px;
  }

  p {
    height: 70px;
  }
}

.deals_oftheday-cardWrapper-inner.is-loading{
  .deals_oftheday-card-header, .rating-span div, span, h6, h5, h2, p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    color: transparent !important; /* make the text invisible */
    user-select: none; 
  }

}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}